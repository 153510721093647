import React from 'react';
import { RichText } from '@graphcms/rich-text-react-renderer';
import { RichTextContent } from '@graphcms/rich-text-types';
import { graphql } from 'gatsby';
import { css } from '@emotion/react';
import { ParagraphHeading } from '../../richtext/ParagraphHeading';

const paragraphStyles = {
    ul: {
        paddingLeft: '1rem',
    },
    li: {
        marginBottom: '0.5rem',
    },
    img: {
        boxShadow: '0 2px 6px grey',
        marginBottom: '2.5rem',
        maxWidth: '700px',
        height: 'auto',
    },
};

export const Paragraph = (paragraph: Queries.HygraphCMS_Paragraph) => {
    return (
        <div css={css(paragraphStyles)}>
            <RichText
                content={paragraph.content.raw as RichTextContent}
                renderers={{
                    h1: ({ children }) => (
                        <ParagraphHeading as="h1">{children}</ParagraphHeading>
                    ),
                    h2: ({ children }) => (
                        <ParagraphHeading as="h2">{children}</ParagraphHeading>
                    ),
                    h3: ({ children }) => (
                        <ParagraphHeading as="h3">{children}</ParagraphHeading>
                    ),
                    h4: ({ children }) => (
                        <ParagraphHeading as="h4">{children}</ParagraphHeading>
                    ),
                    h5: ({ children }) => (
                        <ParagraphHeading as="h5">{children}</ParagraphHeading>
                    ),
                    h6: ({ children }) => (
                        <ParagraphHeading as="h6">{children}</ParagraphHeading>
                    ),
                    img: ({ height, width, src, title, altText }) => (
                      <img
                        src={src}
                        alt={altText}
                        width={width}
                        height={height}
                        title={title}
                        loading="lazy"
                      />
                    ),
                }}
            />
        </div>
    );
};

export const paragraphFragment = graphql`
    fragment HygraphCMS_Paragraph on HygraphCMS_Paragraph {
        id
        component
        content {
            raw
        }
    }
`;
