import { css } from '@emotion/react';
import { graphql } from 'gatsby';
import React, { useEffect, useState } from 'react';
import {
    Badge,
    Button,
    Card,
    FormLabel,
    ToggleButton,
    ToggleButtonGroup,
} from 'react-bootstrap';

type PricingModuleProps = {
    pricingModule: Queries.HygraphCMS_PricingModule;
    page: Queries.HygraphCMS_Page;
};

export const PricingModule = ({
    pricingModule: {
        defaultUsers,
        currency,
        priceLevels,
        priceDescription,
        usersLabel,
        paymentLabel,
        yearlyPaymentText,
        monthlyPaymentText,
        yearlyPaymentDiscountText,
    },
    page,
}: PricingModuleProps) => {
    const getPriceLevel = (users: number) => {
        return priceLevels.find((level) => {
            if (level.to) {
                return users >= level.from && users <= level.to;
            } else {
                return users >= level.from;
            }
        });
    };

    const [userCount, setUserCount] = useState(defaultUsers);
    const [contactUs, setContactUs] = useState(false);
    const [payAnnually, setPayAnnually] = useState(1);
    const [pricePerUser, setPricePerUser] = useState('');

    useEffect(() => {
        const priceLevel = getPriceLevel(userCount);

        if (!priceLevel) {
            return;
        }

        const price = payAnnually
            ? priceLevel.price
            : priceLevel.pricePayedMonthly;

        setContactUs(priceLevel.contactUs);

        const formatter = new Intl.NumberFormat(
            page.locale === 'se' ? 'sv-SE' : 'en-US',
            {
                style: 'currency',
                currency,
                maximumSignificantDigits: 10,
            },
        );

        setPricePerUser(formatter.format(price));
    }, [userCount, payAnnually]);

    return (
        <Card css={css({ boxShadow: '0px 3px 14px rgba(197, 197, 197, 0.3)' })}>
            <Card.Body>
                <div className="position-relative">
                    {contactUs && (
                        <div
                            css={css({
                                display: 'flex',
                                position: 'absolute',
                                top: '-2%',
                                right: 0,
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '105%',
                                width: '100%',
                                backgroundColor: '#fff',
                                zIndex: 11,
                            })}
                        >
                            <Button>Contant us 🤩</Button>
                        </div>
                    )}
                    <span className="display-1">{pricePerUser}</span>
                    <small className="d-block">{priceDescription}</small>
                </div>
                <div className="mt-3">
                    <FormLabel className="mb-0 me-1">{usersLabel}</FormLabel>
                    <ToggleButtonGroup
                        type="radio"
                        name="userCount"
                        value={userCount}
                        onChange={setUserCount}
                    >
                        {priceLevels.map((level, index) => (
                            <ToggleButton
                                id={`levelTgl${index}`}
                                key={index}
                                variant="outline-dark"
                                value={level.from}
                            >
                                {level.to ? (
                                    <span>
                                        {level.from} - {level.to}
                                    </span>
                                ) : (
                                    <span>{level.from} +</span>
                                )}
                            </ToggleButton>
                        ))}
                    </ToggleButtonGroup>
                </div>
                <div className="mt-2">
                    <FormLabel className="mb-0 me-1">{paymentLabel}</FormLabel>
                    <ToggleButtonGroup
                        type="radio"
                        name="payAnnually"
                        value={payAnnually}
                        onChange={setPayAnnually}
                    >
                        <ToggleButton
                            id="tbg-btn-payAnnually-1"
                            variant="outline-dark"
                            value={1}
                        >
                            <span className="d-inline-block mr-2">
                                {yearlyPaymentText}
                            </span>
                            <Badge
                                pill
                                bg="primary"
                                className="ml-2 text-dark"
                                css={css({
                                    marginBottom: '-1rem;',
                                    marginLeft: '.5rem',
                                })}
                            >
                                {yearlyPaymentDiscountText}
                            </Badge>
                        </ToggleButton>
                        <ToggleButton
                            id="tbg-btn-payAnnually-2"
                            variant="outline-dark"
                            value={0}
                        >
                            {monthlyPaymentText}
                        </ToggleButton>
                    </ToggleButtonGroup>
                </div>
            </Card.Body>
        </Card>
    );
};

export const PricingModuleFragment = graphql`
    fragment HygraphCMS_PriceLevel on HygraphCMS_PriceLevel {
        from
        to
        price
        pricePayedMonthly
        contactUs
    }
    fragment HygraphCMS_PricingModule on HygraphCMS_PricingModule {
        stage
        locale
        id
        component
        currency
        defaultUsers
        priceDescription
        usersLabel
        paymentLabel
        yearlyPaymentText
        monthlyPaymentText
        yearlyPaymentDiscountText
        priceLevels {
            ...HygraphCMS_PriceLevel
        }
    }
`;
